.event-manager-item-icon {
  position: relative;
  width: 34px;
  height: 24px;
  overflow: visible;

  &__text {
    line-height: 24px;
  }
}
