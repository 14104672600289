.dnd {
  &--dragging {
    & > * {
      opacity: 0;
    }
  }

  &--moving {
    opacity: 0;
  }
}

.dnd-white-box {
  background-color: color('white');
  border-radius: 3px;

  &:hover {
    position: relative;
    z-index: z-index(top);
    @extend .shadow--dnd-hover;
  }

  &--dragging {
    @extend .shadow--dnd-small;
    z-index: z-index(tooltip);
  }
}
