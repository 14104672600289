.series {
  text {
    @extend .text--medium;
    font-size: $font-size--12;
    fill: color('white');
  }

  &--line-path {
    path {
      stroke-width: 2px;
      fill: none;
    }
  }

  &--dots {
    circle {
      &.dot {
        stroke-width: 0;

        &--bordered {
          stroke-width: 1.5px;
        }

        &:hover {
          stroke-width: 5px;
        }
      }
    }
  }

  &--linear-bar {
    rect {
      stroke: none;

      &:hover {
        stroke: rgba(color('white'), 0.4);
        stroke-width: 2px;
      }
    }
  }

  &--gradient {
    rect {
      border-radius: 5px;
    }
  }

  &--block {
    text {
      @extend .text--bold;
      font-size: $font-size--12;
      fill: color('black');
      text-anchor: middle;
      dominant-baseline: central;
    }
  }

  &__tooltip {
    max-width: 300px;
  }
}
