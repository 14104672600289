.flex-grow--1 {
  flex-grow: 1;
}

.flex-shrink--0 {
  flex-shrink: 0;
}

.flex-shrink--1 {
  flex-shrink: 1;
}

.flex--1 {
  flex: 1;
}

.flex--auto {
  flex: 1;
  min-width: 0;
  min-height: 0;
}
