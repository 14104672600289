@use "sass:math";

$grid-columns: 12;

@mixin col($name) {
  @for $i from 1 to $grid-columns + 1 {
    $ps: percentage(math.div($i, $grid-columns));

    .col#{$name}-#{$i} {
      flex-basis: #{$ps};
      max-width: #{$ps};
    }
  }
}

.flex-grid {
  @extend .container;
  @extend .container--horizontal;
  @extend .container--wrap;

  &--gap-decreased {
    margin: math.div(-$size-decreased, 2);

    > * {
      padding: math.div(-$size-decreased, 2);
    }
  }

  &--gap-large {
    margin: math.div(-$size-large, 2);

    > * {
      padding: math.div(-$size-large, 2);
    }
  }
}

@include col('');

@include media-breakpoint-down(m) {
  @include col('-s');
}

@include media-breakpoint-down(l) {
  @include col('-m');
}
