@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 1s linear infinite;
}

.rotating-reversed {
  animation: rotating 1s linear infinite reverse;
}

.rotate-minus-45 {
  transform: rotate(-45deg);
}

.rotate-flip {
  transform: rotate(180deg);
}

.rotate-translate {
  transition: transform 0.4s ease;
}
