.container {
  display: flex;

  &--centered {
    align-items: center;
    justify-content: center;
  }

  &--vertical {
    flex-direction: column;
  }

  &--vertical-reversed {
    flex-direction: column-reverse;
  }

  &--horizontal {
    flex-direction: row;
    align-items: center;
  }

  &--horizontal-reversed {
    flex-direction: row-reverse;
    align-items: center;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-space {
    justify-content: space-between;
  }

  &--inversed {
    justify-content: flex-end;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--align-center {
    align-content: center;
    align-items: center;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--stretch-items {
    align-items: stretch;
  }

  &--baseline {
    align-items: baseline;
  }

  &__item {
    &--start {
      align-self: flex-start;
    }

    &--end {
      align-self: flex-end;
    }

    &--centered {
      align-self: center;
    }

    &--stretch {
      align-self: stretch;
    }
  }
}
