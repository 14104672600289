.pie-chart {
  width: 192px;
  height: 96px;
  position: relative;

  &--compact {
    width: 180px;
    height: 90px;
  }
}
