.train-information {
  &__commuter-line {
    @extend .text__m-white;
    @extend .text--center;
    display: inline-block;
    min-width: $size-large;
    padding: $size-smallest;
    border-radius: $border-radius-small;
    background-color: color('ui-black3');
  }

  &__schedule-status {
    @extend .text__m-white;
    @extend .text--center;
    display: inline-block;
    min-width: $size-xxlarge;
    padding: $size-smallest $size-small;
    border-radius: $border-radius-small;
    background-color: color('ui-black3');
  }

  &__delay-marker {
    @extend .text__s-white;
    @extend .text--center;
    @extend .text--light;
    display: inline-block;
    width: $size-large;
    height: $size-large;
    padding: $size-smallest 0;
    border-radius: 50%;

    &--small {
      width: $size-decreased;
      height: $size-decreased;
    }

    &--red {
      background-color: color('red');
    }

    &--blue {
      background-color: color('ui-blue');
    }

    &--orange {
      background-color: color('orange');
    }

    &--green {
      background-color: color('green');
    }
  }
}
