.group-list {
  padding-bottom: customRem(78);

  &__section {
    position: relative;
    background-color: color('ui-black2');

    &--collapsable {
      @include transition(max-height);
      max-height: 390px;
      overflow: hidden;
      will-change: max-height;

      .group-list--collapsed & {
        max-height: 0;
      }
    }
  }
}
