$thumb-color: rgba(59, 75, 106, 0.8);

@mixin scrollbar() {
  scrollbar-color: $thumb-color transparent;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 5px;
  }
}
