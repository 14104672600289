$border-colors: (
  'grey': color('ow-grey'),
  'light-grey': color('ow-grey2'),
  'dark-grey': color('ui-black4'),
  'black': color('ui-black3'),
  'dark': color('shadow-std'),
);
$border-sides: left top bottom right;

@each $color-name, $color in $border-colors {
  .border--#{$color-name} {
    border: 1px solid $color;
  }

  @each $side in $border-sides {
    .border-#{$side}--#{$color-name} {
      border-#{$side}: 1px solid $color;
    }
  }
}

.border--thick {
  border-width: 2px;
}

.border--grey-thick {
  box-shadow: inset 0 0 0 2px color('ui-black3');
}
