.notification-list-item {
  position: relative;
  background-color: color('ui-black1');

  &__l-column {
    padding-right: 24px;
  }

  &--not-read {
    background-color: color('ui-black2');

    &::before {
      display: block;
      position: absolute;
      top: 24px;
      right: 30px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: color('ui-blue');
      content: '';
    }
  }
  .text-box {
    word-wrap: break-word;
    overflow: hidden;
  }
}
