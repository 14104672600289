$sidebar-width: 280px;

.dashboard-sidebar-button {
  width: $sidebar-width;
  box-sizing: border-box;
}

.dashboard-sidebar-round-button {
  background-color: color('event-list-black');
  border-radius: 50%;
}

.dashboard-remove-row__button {
  visibility: hidden;

  .dashboard-remove-row:hover & {
    visibility: visible;
  }
}

.dashboard-sidebar {
  transition: transform 0.2s;
  right: 0;
  width: $sidebar-width;
}

.dashboard-sidebar--open {
  position: static;
  transform: translateX(0);
}

.dashboard-sidebar--closed {
  position: absolute;
  transform: translateX($sidebar-width);
}
