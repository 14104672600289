@mixin transition-all() {
  transition: all time(normal) ease-in-out;
}

@mixin transition($prop) {
  transition: $prop time(normal) ease-in-out;
}

@mixin transition-none() {
  transition: none;
}
