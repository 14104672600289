// full view
.weather-charts-container {
  background-color: color('event-list-black');
}

.block-chart {
  @extend .flex-shrink--0;
  height: 44px;
}

.icons-chart {
  @extend .flex-shrink--0;
  height: 80px;
}

// compact view
.dashboard-weather {
  &__icon {
    @extend .padding--large;
    position: relative;
    border-radius: 100%;
  }

  &__markers {
    position: absolute;
    top: 0;
    left: -16px;
    width: 32px;
    padding-top: 10px;
  }

  &__metrics {
    @extend .padding-bottom--tiny;
    @extend .text__l-white;
    overflow: hidden;
  }

  &__metric {
    margin-right: 1.5vw;

    &__icon {
      @extend .margin-bottom--large;
      @extend .padding-bottom--smallest;
      @include transition(transform);
      align-self: flex-end;
      will-change: transform;
    }

    &__text {
      align-self: flex-end;
      font-size: 50px;
    }

    &__unit {
      @extend .padding-bottom--smallest;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 1px;

      &--degr {
        font-size: 16px;
      }

      &--ms {
        letter-spacing: 2px;
      }

      &--c-degr {
        font-size: 14px;
      }
    }
  }
}
