@use "sass:color";
@use "sass:map";
$text-sizes: (
  xxl: $font-size--16,
  xl: $font-size--15,
  l: $font-size--14,
  m: $font-size--13,
  s: $font-size--12,
  xs: $font-size--11,
  xxs: $font-size--10,
);

$text-colors: (
  'grey': color('ui-black5'),
  'grey-txt': color('grey-texts'),
  'black': color('ui-black1'),
  'ow-pale': color('ow-grey3'),
  'white': color('white'),
  'blue': color('ui-blue'),
  'navy': color('ui-black2'),
  'red': color('red'),
  'green': color('green'),
  'yellow': color('yellow'),
  'light-yellow': color('light-yellow'),
  'ow-grey': color('ow-grey'),
  'current': currentColor,
);

$lhs: (
  0: 0,
  std: 1.2,
  large: 1.33,
  x-large: 1.5,
  f-large: 30px,
  f-xlarge: 40px,
);

@each $lh-size-name, $lh-size-value in $lhs {
  .text--lh-#{$lh-size-name} {
    line-height: $lh-size-value;
  }
}

@each $text-color-name, $text-color-value in $text-colors {
  @each $text-size-name, $text-size-value in $text-sizes {
    .text__#{$text-size-name}-#{$text-color-name} {
      // regular
      font-family: 'montserrat', sans-serif;
      font-weight: 500;
      color: $text-color-value;
      font-size: $text-size-value;
    }
  }
}

.text {
  &__header-mobile {
    color: color('black');
    font-size: $font-size--20;
  }

  &__header-grey-txt {
    color: color('grey-texts');
    font-size: $font-size--18;
  }

  &__modal-title {
    color: color('black');
    font-size: $font-size--22;
  }

  &__white-title {
    font-size: $font-size--22;
    color: color('white');
  }

  &__main-nav {
    color: color('ui-black4');
    font-size: $font-size--16;

    &--active {
      color: color('white');
    }
  }

  &__m-soft-green {
    font-family: 'montserrat', sans-serif;
    font-weight: 500;
    color: color('soft-green');
    font-size: $font-size--13;
  }

  &__m-dashboard-red {
    font-family: 'montserrat', sans-serif;
    font-weight: 500;
    color: color('dashboard-red');
    font-size: $font-size--13;
  }

  &__app-name {
    color: color('white');
    font-size: $font-size--22;
    white-space: nowrap;
    overflow: visible;
  }

  &--light {
    font-family: 'montserrat', sans-serif;
    font-weight: 300;
  }

  &--medium {
    font-family: 'montserrat', sans-serif;
    font-weight: 400;
  }

  &--regular {
    font-family: 'montserrat', sans-serif;
    font-weight: 500;
  }

  &--bold {
    font-family: 'montserrat', sans-serif;
    font-weight: 700;
  }

  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--middle {
    vertical-align: middle;
  }

  &--wrap {
    white-space: pre-wrap;
    word-wrap: normal;
  }

  &--upper {
    text-transform: uppercase;
  }

  &--no-wrap {
    white-space: nowrap;
    overflow: hidden;
  }

  &--error {
    color: color('red');
  }

  &--ellipsis {
    text-overflow: ellipsis;
  }

  &--underline {
    text-decoration: underline;
  }

  &--no-overflow {
    text-overflow: inherit;
    overflow: visible;
  }

  &--word-break {
    word-break: break-word;
  }

  &--selectable {
    user-select: text;
  }

  &--link {
    @extend .text--medium;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &--blue-link {
    @extend .text--medium;
    color: color('ui-light-blue');
    cursor: pointer;

    &:hover {
      color: color.adjust(map.get($colors, 'ui-light-blue'), $lightness: 10%);
    }
  }

  &--stretched {
    letter-spacing: 1px;
  }

  &--lb {
    white-space: pre-line;
  }
}

.centered-text {
  line-height: 1;
  vertical-align: baseline;
}
