$pictogram-size: 40px;

.private-channel-item {
  position: relative;

  &__pictogram {
    @extend .container;
    @extend .container--centered;
    width: $pictogram-size;
    height: $pictogram-size;
    border-radius: 100%;

    &--commander {
      background-color: color('yellow');
    }

    &--follower {
      background-color: color('green');
    }

    &--pending {
      background-color: color('ui-black3');
    }

    &--left {
      background-color: color('red');
    }
  }
}
