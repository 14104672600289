$z-index: (
  below: -1,
  bottom: 1,
  middle: 2,
  top: 3,
  overlay: 100,
  header: 200,
  header-button: 210,
  global-messaging: 400,
  menu: 500,
  app-header: 600,
  notifications: 1500,
  modal: 2000,
  dropdown-menu: 2500,
  tooltip: 3000,
);

@function z-index($key) {
  @if map-has-key($z-index, $key) {
    @return map-get($z-index, $key);
  }

  @warn 'Unknown `#{$key}` in $z-index.';
  @return null;
}

@each $z-index-name, $z-index-value in $z-index {
  .z-index--#{$z-index-name} {
    z-index: #{$z-index-value};
  }
}
