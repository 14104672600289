$form-element-std: 40px;
$form-element-header: 36px;
$form-element-big: 48px;
$form-component-border-radius: $border-radius-default;
$form-component-ow-border-color: color('ow-grey2');
$form-component-ow-placeholder-color: color('ow-grey3');
$form-component-ow-color: color('grey-texts');

.form-component {
  border-radius: $form-component-border-radius;

  &--white {
    background-color: color('white');
  }

  &--black {
    background-color: color('ob-black');
  }

  &--outlined-white {
    border: 2px solid color('ow-grey2');
  }

  &--blue {
    background-color: color('ui-blue');
  }

  &--blue-h {
    background-color: color('ui-blue');

    &:hover {
      background-color: color('ui-blue-h');
    }
  }

  &--size-header {
    height: $form-element-header;
  }

  &--size-big {
    height: $form-element-big;
  }

  &--size-std {
    height: $form-element-std;
  }
}
