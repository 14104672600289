.animation--fade-in {
  animation: fadeIn time(normal) ease both;
}

.animation--fade-out {
  animation: fadeIn time(normal) ease reverse both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animation--show {
  animation: showIn time(normal) ease both;
}

@keyframes showIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
