.cdm-widget {
  @extend .padding-bottom--std;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
  grid-auto-rows: minmax(180px, 220px);
  grid-gap: $size-xlarge $size-small;
  align-content: start;
  overflow: hidden;

  &--tile {
    grid-auto-rows: 120px;
  }
}
