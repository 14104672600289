.event-manager-item {
  position: relative;
  min-height: 80px;
  background-color: color('event-list-black');

  &__section {
    min-width: 180px;
  }

  &__m-section {
    max-width: 180px;
  }

  &__actions {
    align-self: stretch;
    width: 64px;
  }
}
