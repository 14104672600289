.scrollable {
  overflow: auto;

  &--vertical {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--horizontal {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.no-scrollable {
  overflow: hidden;
}
