.user-modal {
  &__container {
    @include transition(transform);
    position: relative;
  }

  &__l-container {
    padding-right: 66px;
  }
}
