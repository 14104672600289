.trend {
  &__unit {
    font-weight: 300;
  }
}

.trend-variant--regular {
  .trend {
    &__trend {
      @extend .margin-left--smallest;
    }

    &__value {
      font-size: 50px;
    }

    &__unit {
      font-size: 20px;
    }
  }
}

.trend-variant--small {
  .trend {
    &__trend {
      @extend .margin-left--small;
    }

    &__value {
      font-size: 36px;
    }

    &__unit {
      font-size: 16px;
    }

    &__subvalue {
      font-size: 14px;
    }

    &__subunit {
      @extend .text__l-grey-txt;
      font-size: 14px;
    }
  }
}
