$animation-speed: 0.7s;
$closed-scale: 0.1;
$open-scale: 20;

.alert-overlay {
  overflow: hidden;
  border-radius: $border-radius-small;
  z-index: z-index(overlay);

  &__body {
    animation: bodyIn $animation-speed forwards;
    color: color('white');
  }

  &--warning &__body {
    color: color('ui-black1');
  }

  &__ripple {
    width: 200px;
    height: 200px;
    position: absolute;
    top: -70px;
    left: -70px;
    border-radius: 100%;
    animation: rippleIn $animation-speed forwards;
  }

  &--normal &__ripple {
    background-color: color('green');
  }

  &--warning &__ripple {
    background-color: color('yellow');
  }

  &--alert &__ripple {
    background-color: color('red');
  }

  &--hide {
    pointer-events: none;
  }

  &--hide &__ripple {
    animation: rippleOut $animation-speed forwards;
  }

  &--hide &__body {
    animation: bodyOut $animation-speed forwards;
  }
}

@keyframes rippleIn {
  0% {
    opacity: 0;
    transform: scale($closed-scale);
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale($open-scale);
  }
}

@keyframes rippleOut {
  0% {
    transform: scale($open-scale);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale($closed-scale);
  }
}

@keyframes bodyIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bodyOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
