.full-size {
  width: 100%;
  height: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.max-full-height {
  max-height: 100%;
}

.max-full-width {
  max-width: 100%;
}

.hidden {
  display: none;
}

.block {
  display: block;
}
