.panel {
  &--grey {
    background-color: color('ow-grey');
  }

  &--white {
    background-color: color('white');
  }

  &--black {
    background-color: color('ob-black');
  }
}
