.pointer,
.cursor--pointer {
  cursor: pointer;
}

.cursor--grab {
  cursor: grab;
}

.cursor--default {
  cursor: default;
}
