@use "sass:color";
$size-xxlarge: customRem(40);
$size-xlarge: customRem(30);
$size-large: customRem(20);
$size-std: customRem(16);
$size-decreased: customRem(12);
$size-small: customRem(8);
$size-smaller: customRem(6);
$size-smallest: customRem(4);
$size-tiny: customRem(2);

$colors: (
  // Color.Black
  'black': #000,
  // Color.White
  'white': #fff,
  // Color.PrimaryLight
  'ui-blue': #417eff,
  // Color.PrimaryPale
  'ui-light-blue': #7ca6ff,
  // deprecated
  'dnd-placeholder': #213a6b,
  // deprecated
  'ui-blue-dnd': rgba(#417eff, 0.3),
  // deprecated
  'ui-blue-dnd-over': rgba(#417eff, 0.6),
  // deprecated
  'ui-blue-dnd-over-dark': rgba(#213a6b, 0.9),
  // deprecated use Color.PrimaryLight
  'ui-blue-h': #2e70ff,
  // Color.TimelineBackground
  'ui-black1': #14202c,
  // Color.UiBlack2
  'ui-black2': #232d43,
  // Color.ChartBase
  'ui-black3': #3b4b6a,
  // Color.MenuButtonColor
  'ui-black4': #6780aa,
  // deprecated use Color.MenuButtonColor
  'ui-black5': #879dc1,
  // deprecated use Color.Primary
  'ui-mobile-blue': #215de1,
  // deprecated use Color.PrimaryLight
  'clear-blue': #266ff5,
  // deprecated use Color.NavyBlue
  'event-list-black': #1b2437,
  // Color.Violet
  'dashboard-violet': #6c55a7,
  // Color.SeaBlue
  'dashboard-sea-blue': #4dc8c9,
  // Color.Magenta
  'dashboard-magenta': #da58ab,
  // Color.Red
  'dashboard-red': #e42626,
  // Color.Red
  'dashboard-light-green': #45eb4d,
  // Color.LightGreen
  'dashboard-black': #2b3853,
  // Color.Grey1
  'ow-grey': #f7f7fa,
  // Color.DisabledText
  'ow-grey2': #c6c7cb,
  // Color.Grey3
  'ow-grey3': #858891,
  // Color.Black
  'ob-black': #050a10,
  // Color.Grey
  'grey-texts': #69728B,
  // Color.SoftGreen
  'soft-green': #81c48d,
  // Color.Green
  'green': #32a538,
  // Color.Orange
  'orange': #ff6d00,
  // deprecated use Color.Red
  'red': #d50000,
  // deprecated use Color.Yellow
  'yellow': #ffd145,
  // deprecated use Color.Yellow
  'light-yellow': color.adjust(#ffd145, $lightness: 0.15%),
  // deprecated
  'shadow-big': rgba(#000, 0.4),
  // deprecated
  'shadow-std': rgba(#000, 0.2),
  // deprecated
  'shadow-small': rgba(#000, 0.15)
);

@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn 'Unknown `#{$key}` in $color.';
  @return null;
}

$blue-gradient: linear-gradient(180deg, #2d86e6 0%, #0f4aca 100%);

$font-size--22: customRem(22);
$font-size--20: customRem(20);
$font-size--18: customRem(18);
$font-size--16: customRem(16);
$font-size--15: customRem(15);
$font-size--14: customRem(14);
$font-size--13: customRem(13);
$font-size--12: customRem(12);
$font-size--11: customRem(11);
$font-size--10: customRem(10);

$border-radius-small: 3px;
$border-radius-default: 5px;
$border-radius-round-filter: 14px;

$round-tabs-height: 28px;

$grid-breakpoints: (
  s: 0,
  m: 1366px,
  l: 1900px,
);
