$sides: (
  top: vertical,
  bottom: vertical,
  right: horizontal,
  left: horizontal,
);

$sizes: (
  xx-large: $size-xxlarge,
  x-large: $size-xlarge,
  large: $size-large,
  std: $size-std,
  decreased: $size-decreased,
  small: $size-small,
  smaller: $size-smaller,
  smallest: $size-smallest,
  tiny: $size-tiny,
  auto: auto,
);

@each $side, $orientation in $sides {
  @each $size-name, $size in $sizes {
    .padding,
    .padding-#{$side},
    .padding-#{$orientation} {
      &--#{$size-name} {
        $padding-side: 'padding-#{$side}';
        #{$padding-side}: $size;
      }
    }

    .margin,
    .margin-#{$side},
    .margin-#{$orientation} {
      &--#{$size-name} {
        $margin-side: 'margin-#{$side}';
        #{$margin-side}: $size;
      }
    }
  }
}
