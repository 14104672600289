.axis {
  line,
  path {
    stroke: none;
    fill: none;
  }

  &--ticks {
    line,
    path {
      stroke: none;
      stroke-width: 0;
      fill: none;
    }
  }
}

.tick {
  line {
    stroke: color('ui-black3');
    stroke-width: 1px;
  }

  text {
    @extend .text--medium;
    font-size: $font-size--13;
    fill: color('grey-texts');
  }

  &--base {
    line {
      stroke: color('grey-texts');
    }

    text {
      fill: color('white');
    }
  }

  &--marked {
    text {
      fill: color('white');
    }
  }
}

.axis-unit {
  @extend .text--medium;
  font-size: $font-size--12;
  fill: color('white');
}
