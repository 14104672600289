$color-map-nav-active: rgba(color('ow-grey2'), 0.3);
$color-map-nav-hover: rgba(color('ow-grey2'), 0.1);

.ol-control {
  @extend .shadow--std;

  position: absolute;
  background-color: color('white');
  overflow: hidden;

  button {
    display: block;

    &:hover {
      background-color: $color-map-nav-hover;
    }

    &:active {
      background-color: $color-map-nav-active;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  button + button {
    border-top: 1px solid color('ow-grey2');
  }
}

.ol-zoom {
  top: 30px;
  left: 30px;
  border-radius: $border-radius-default;

  button {
    width: 36px;
    height: 36px;
    padding: 8px;
  }
}

.ol-rotate {
  bottom: 32px;

  button {
    padding: 8px;
  }

  .svg-icon {
    fill: color('ui-blue-h');
  }

  // its a hack to achieve different icon color, and not reimplementing whole control
  &.ol-hidden {
    display: block;
    visibility: visible;

    .svg-icon {
      fill: color('black');
    }
  }
}

.ol-geo {
  bottom: 96px;

  button {
    padding: 12px;
  }
}

.ol-geo,
.ol-rotate {
  left: 24px;
  border-radius: 50%;

  button {
    width: 48px;
    height: 48px;
  }
}

.ol-touch {
  .ol-zoom {
    top: 20px;
    left: 20px;
    border-radius: $border-radius-small;

    button {
      width: 30px;
      height: 30px;
      padding: 6px;
    }
  }

  .ol-rotate,
  .ol-geo {
    left: 16px;

    button {
      width: 38px;
      height: 38px;
    }
  }

  .ol-geo {
    bottom: 76px;

    button {
      padding: 8px;
    }
  }

  .ol-rotate {
    bottom: 24px;

    button {
      padding: 4px;
    }
  }
}
