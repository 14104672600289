.runways-date {
  @extend .padding-horizontal--std;
  @extend .padding-vertical--small;
  @extend .text__s-white;
  @extend .text--stretched;
  position: relative;
  z-index: z-index(top);
  border-radius: $border-radius-small;
  background-color: color('event-list-black');
}

.runway-graph {
  position: absolute;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.runway-closure {
  @extend .text__m-yellow;
  padding-bottom: 8px;
  padding-top: 2px;
  color: #f8ba00;
  .carousel .carousel-status {
    color: #f8ba00;
    padding-top: 3.2px;
  }
  .carousel .slide {
    text-align: left;
  }
}
