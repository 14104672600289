.flight-table {
  .rt-th.-sort-asc,
  .rt-th.-sort-desc {
    span::after {
      display: inline-block;
      padding-left: 5px;
      color: color('white');
    }
  }

  .rt-th.-sort-asc span::after {
    content: '\25B2';
  }

  .rt-th.-sort-desc span::after {
    content: '\25BC';
  }

  .rt-tr {
    padding: 6px 0;
  }

  .rt-tr.-odd {
    background: color('ui-black2');
  }

  .rt-td,
  .rt-tr {
    align-items: center;
    transition: background-color time(fast), color time(fast);
  }

  .rt-thead {
    &.-header,
    &.-filters {
      position: sticky;
      top: 0;
      background-color: color('ui-black1');
    }

    &.-header {
      z-index: z-index(top) + 1;
    }

    &.-filters {
      top: 30px;
      z-index: z-index(top);
    }
  }

  .rt-tbody {
    .rt-tr:not(.-padRow):hover {
      background-color: rgba(color('ui-black3'), 0.7);
    }

    .rt-tr:not(.-padRow):hover .rt-td {
      color: color('white');
    }
  }

  &--widget {
    .rt-tr.-odd {
      background: transparent;
    }

    .rt-tbody .rt-tr:hover {
      background: transparent;
    }

    .rt-thead.-header {
      background-color: color('event-list-black');
    }

    .rt-thead .rt-tr .rt-th:first-child {
      padding-left: 28px;
    }

    .rt-tbody .rt-tr .rt-td:first-child {
      padding-left: 28px;
    }
  }
}

.flight-table-row {
  &--prm-CLD,
  &--prm-CNL,
  &--prm-C {
    border: 1px solid color('red');
  }

  &--delayed,
  &--prm-DEL,
  &--prm-DLA {
    border: 1px solid color('yellow');
  }

  &--nature-of-flight {
    @extend .text__s-white;
    display: inline-block;
    min-width: 50px;
    padding: $size-smallest;
    border-radius: $border-radius-small;
    letter-spacing: 1px;
  }

  &--routes {
    display: inline-block;
  }
}

.flight-table-equal-columns {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 0.2vw;
  padding: 0 0.5vw;

  &--center {
    align-items: center;
    justify-content: center;
  }
}

.flight-table-cell--darker {
  background-color: rgba(0, 0, 0, 0.2);
}
