.shadow {
  &--large {
    box-shadow: 0 0 20px 0 color('shadow-big');
  }

  &--big {
    box-shadow: 0 0 15px 0 color('shadow-big');
  }

  &--std {
    box-shadow: 0 2px 10px 0 color('shadow-std');
  }

  &--std-inset {
    box-shadow: inset 0 2px 10px 0 color('shadow-std');
  }

  &--popover {
    box-shadow: 0 10px 15px 0 color('shadow-std');
  }

  &--dnd {
    box-shadow: 0 20px 20px 0 color('shadow-big');
  }

  &--dnd-small {
    box-shadow: 0 2px 10px 0 color('shadow-small');
  }

  &--dnd-hover {
    box-shadow: 0 0 5px 0 color('shadow-small');
  }

  &--none {
    box-shadow: none;
  }
}
