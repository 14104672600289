.event-list {
  &__group-container {
    margin-left: -8px;
    margin-right: -8px;
  }

  &__activate-box {
    position: relative;
    height: 80px;
  }
}
