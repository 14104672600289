.whats-new {
  height: 480px;
  width: 800px;

  &__left {
    position: relative;
    overflow: hidden;
    background: $blue-gradient;
    border-radius: $border-radius-default 0 0 $border-radius-default;
    margin-left: -1px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -25px;
      width: 50px;
      opacity: 0.7;

      $bg: #10489c;
      background: radial-gradient(ellipse closest-side, $bg, rgba($bg, 0)) no-repeat;
    }
  }
}
