.flight-table-preview {
  &__item {
    @extend .border-bottom--black;

    &:last-child {
      border: 0;
    }
  }

  &__left-col {
    @extend .margin-right--small;
    width: 53px;
  }

  &__nature-of-flight {
    @extend .text__m-white;
    display: inline-block;
    min-width: $size-xxlarge;
    padding: $size-smallest;
    border-radius: $border-radius-small;
    background-color: color('ui-black3');
    letter-spacing: 1px;
  }

  &__prm {
    @extend .text__s-white;
  }

  &__routes {
    display: inline-block;
  }
}
